.vr-content .banner {
    /* background-color: #7a3e1e; */
    background-image: linear-gradient(to right, #F68E36 , #7a3e1e);
    margin-top: 30px;
    margin-bottom: 30px;    
    color: #ffffff;
}

.vr-content img {
    max-width: 100%;
}


.vr-content .vr-imersive {
    font-size: 32px;
    display: block;
    font-weight: bold;
}

.vr-content .vr-text {
    text-align: center;
    height: 200px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .vr-content img {
        width: 100%;
    }

    .vr-content .vr-text {
        padding: 25px;
    }

    
}