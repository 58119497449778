.navbar {
    padding-top: 0px;
    padding-bottom: 0px;
}

.navbar-nav .nav-link {
    color: #060007;
    padding-bottom: 20px;
    padding-top: 20px;
}

.navbar-nav .nav-link:hover {
    background-color: #060007;
    color: #837d6f;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

@media only screen and (max-width: 600px) {
    .App-logo{
        width: 200px;
    }   
}