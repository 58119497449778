.about {
    background-image: url("../../assets/img/galaxy-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 40vh;
    display: flex;
    align-items: center;
    color: #060007;
    color: #ffffff;
    padding: 30px;
}

.about .title {
    max-height: 70px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.about h3 {
    text-align: center;
}

.hourglass {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
