.purchase-page {
    background-image: url("../../assets/img/bg-utlimato.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    text-align: center;
    color: #060007;
    text-shadow: 1px 1px #adadad;
    min-height: 70vh;
    padding: 40px 0px;
}

.purchase-page h3 {
    color: #ffffff;
    font-size: 18px;
    font-weight: normal;
    margin-top: 75px;
    margin-bottom: 55px;
}

.purchase-page .title {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
}

.purchase-page .date {
    font-size: 12px;
}

@media only screen and (max-width: 768px) {
    .purchase-page {
        background-image: url("../../assets/img/bg-utlimato-2.jpg");
        background-size: 150%;
    }    
}