.footer {
    padding: 30px 0px;
    background-color: #060007;
    color: #837d6f;
}



@media only screen and (max-width: 768px) {
  .footer{
    padding: 5px 0px;
  }
  .text-footer{
    font-size: x-small;
  }
  .text-right {
    text-align: right;
    justify-content: end;
    align-self: flex-end;
}
}