.soon-page {
    background-image: url("../../assets/img/bg-utlimato-2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    color: #060007;
    text-shadow: 1px 1px #adadad;
    color: #ffffff;
    min-height: calc(100vh - 196px);
    padding: 40px 0px;
    display: flex;
    align-items: center;
}