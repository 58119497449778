.line-up {
    background-image: url("../../assets/img/line-up.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 80vh;
    text-align: center;
    color: #060007;
    text-shadow: 1px 1px #adadad;
}

.line-up img {
    max-width: 90%;
}

.line-up .title {
    max-height: 70px;
    margin-top: 40px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .text{
    display: none;
  }
}