.events {
    background-color: #cec1a1;
    margin-top: 25px;
    border-radius: 8px;
    text-align: left;
    margin-bottom: 60px;
}

.events h4 {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 20px;
    text-align: center;
}

.events .event-content {
    padding-left: 30px;
    padding-right: 30px;
}

.events .image {
    margin-bottom: 15px;
}

.events .image img {
    width: 100%;
}

.purchase-page .event p {
    font-size: 14px;
    line-height: 16px;
}

.purchase-page .event .title {    
    font-size: 14px;
    display: block;
}

.purchase-page .event .info {
    display: block;
    text-transform: uppercase;
}