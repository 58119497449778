.purchase {
    background-image: url("../../assets/img/purchase-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    color: #060007;
    color: #ffffff;
    height: 370px;
    text-align: center;
}

.purchase p{
    font-size: 28px;
    line-height: 28px;
    text-shadow: 1px 1px #000000;
}

.purchase a {
    background-color: #060007;
    color: #ffffff;
    text-transform: uppercase;
    padding: 10px 15px;
    border-radius: 30px;
    font-size: 12px;
    text-decoration: none;
    font-weight: bold;
}